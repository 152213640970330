.c-main{
    padding-top:1em;
}
//color
body{
    background-color: #f3f6f8;
}
a, a:hover {
    color: #2273ba;
}
a.deco-none:hover{
    text-decoration: none;
}
.c-header{
    border-bottom: none;
}
.c-header .c-subheader{
    background-color: #f3f6f8;
    font-size: 0.9em;
    border-bottom: 1px solid #d8dbe0;
}
.c-sidebar{
    color: $G_NAVI_FONT_COLOR;
    border-right: 1px solid #d8dbe0;
}
.c-sidebar .c-sidebar-brand, .c-sidebar .c-sidebar-header{
    background-color: $G_NAVI_BASE_COLOR;
}
.c-sidebar-nav{
    background-color: $G_NAVI_BASE_COLOR;
}
.c-sidebar .c-sidebar-nav-dropdown.c-show{
    background-color: $G_NAVI_DROPDOWN_COLOR;
}
.c-sidebar .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown-toggle{
    color: $G_NAVI_FONT_COLOR;
}
.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle{
    color: $G_NAVI_FONT_COLOR;
}
.c-sidebar .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown-toggle,
.c-sidebar.c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown{
    &:hover{
        background: $G_NAVI_ACTIVE_COLOR !important;
        color: $G_NAVI_ACTIVE_FONT_COLOR !important;
    }
}
.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-link, .c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-dropdown-toggle{
        background: $G_NAVI_ACTIVE_COLOR !important;
        color: $G_NAVI_ACTIVE_FONT_COLOR !important;
        border-right: 1px solid $G_NAVI_ACTIVE_COLOR;
}
.c-sidebar .c-sidebar-nav-link.c-active, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle{
    background: rgba(255, 255, 255, 0.5);
    color: $G_NAVI_FONT_COLOR;
}
.c-sidebar .c-sidebar-nav-link.c-active .c-sidebar-nav-icon, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon{
    color: $G_NAVI_FONT_COLOR;
}

.c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown:hover > .c-sidebar-nav-dropdown-items{
    background: $G_NAVI_BASE_COLOR !important;
    border-bottom: 1px solid #eee;
    border-right: 1px solid #eee;
}
.c-sidebar .c-sidebar-minimizer {
    background: #d2d2d2;
    &:hover{
        background: #bbb;
    }
}

.form-control:focus {
    border-color: #649dcf;//#2273ba 70%
    box-shadow: 0 0 0 0.2rem rgba(34, 115, 186, 0.25);
} 

//breadcrumb
.c-subheader{
    height: auto;
}
.breadcrumb{
    padding-bottom:6px;
}

//pager
.page-link, .page-link:hover{
    color: #636f83;
}
.page-item.active .page-link{
    background-color: #828b9b;
    border-color: #828b9b;
}

//table
.clickable{
    cursor: pointer;
}
.table th, .table td{
    vertical-align: middle;
}
.table thead th{
    vertical-align: middle;
}

//form
.form-group label{
    font-weight:bold;
}
.form-group label.form-check-label{
    font-weight: normal;
}

.help-block{
    font-size:0.9em;
    color:#bac0c8;
    line-height:1.3em;
    margin-bottom:0;
}
legend{
    font-size: 1rem;
    margin-bottom:5px;
}
.invalid-feedback{
    display: block;
    font-weight: bold;
}

//button
.btn-box{
    text-align: center;
    margin: 0 auto 30px auto;
    button{
        color: #fff;
        margin-left:10px;
        margin-right: 10px;
        margin-bottom: 15px;
    }
    a{
        color: #fff;
        margin-left:10px;
        margin-right: 10px;
        margin-bottom: 15px;
    }
    a:not([href]):not([tabindex]), a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus{
        color: #fff;
    }
}

.btn-primary,
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #2273ba;
  border-color: #2273ba;
}
.btn-primary:focus, .btn-primary.focus,
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(34, 115, 186, 0.5);
}
.btn-primary:hover {
  color: #fff;
  background-color: #1b5c94;
  border-color: #1b5c94;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #1b5c94;
  border-color: #1b5c94;
}

//header account menu
.arrow-down{
    width: 0;
    height: 0;
    border-top: solid 5px #636f83;
    border-right: solid 5px transparent;
    border-bottom: solid 5px transparent;
    border-left: solid 5px transparent;
    display: inline-block;
    vertical-align: text-bottom;
    margin-left: 5px;
}


//search area collapse
.search-box .card-header{
    padding: 0.45rem 1.25rem;
}
.search-box .search-title{
    margin-top: 7px;
}
.search-box .card-header a[aria-expanded="false"]:after {
    content: "";
    display:block;
    height:20px;
    width:20px;
    background-size: 20px 20px;
    background-image: url('/assets/icons/chevron-circle-up-alt.svg');
    background-repeat: no-repeat;
}
.search-box .card-header [aria-expanded="true"]:after {
    content: "";
    display:block;
    height:20px;
    width:20px;
    background-size: 20px 20px;
    background-image: url('/assets/icons/chevron-circle-down-alt.svg');
    background-repeat: no-repeat;
}

//badge
.status-badge{
    color: #fff;
    font-size: 0.8em;
    min-width:94px;
    padding:6px 0 7px 0;
}
.recommend-badge{
    width:60px;
    margin-right:10px;
}

//category select
.category-list ul{
    list-style: none;
}
.category-list li.parent-cate{
    margin:10px 0;
}
.category-list ul li ul {
    margin-top: .5em;
    margin-bottom: .75em;
}
     
.category-list ul li ul li {
    margin-bottom: .25em;
    position: relative;
}
     
.category-list ul li ul li::before {
    content: "";
    position: absolute;
    top: -0.5em;
    left: -16px;
    width: 10px;
    height: calc(100% + .75em);
    border-left: 1px solid #ccc;
}

.category-list ul li ul li:last-child::before {
    height: calc(1em + .25em);
}

.category-list ul li ul li::after {
    content: "";
    position: absolute;
    top: .75em;
    left: -16px;
    width: 10px;
    border-bottom: 1px solid #ccc;
}

//modal
.modal-footer{
    display: inline;
}

//tabs
.nav-tabs-boxed .tab-content{
    color: #3c4b64;
}

//image
ul#sortable-image{
    list-style: none;
    overflow: hidden;
    padding:0;
}
ul#sortable-image li{
    width: 50%;
    float: left;
}
ul#sortable-image li img{
    width: 90%;
    border: 3px solid #fff;
    margin-bottom: 3px;
}
ul#sortable-image li img.mainImg{
    border: 3px solid #2273ba;
}
ul#sortable-image li dl{
    margin-bottom:15px;
}
ul#sortable-image li dt{
    text-align: center;
}
ul#sortable-image li dd{
    width: 85%;
    overflow: hidden;
    font-size: 0.85em;
    margin:0 auto;
}

//category setting
.category-box{
    border-top:1px solid #d8dbe0;
    border-right:1px solid #d8dbe0;
}
.category-box ol{
    padding-left:0;
}
.category-box li{
    background: #fff url('/assets/icons/options.svg') no-repeat 5px 13px;
    background-size: 15px 15px;
    border-left:1px solid #d8dbe0;
    border-bottom:1px solid #d8dbe0;
    overflow: hidden;
}
.category-box .panel-collapse{
    margin-top: 15px;
    padding-left: 30px;
    border-top:1px solid #d8dbe0;
    background-color: #e3e5e9;
    clear: both;
}
.category-box .panel-collapse .panel-collapse{
    background-color: #d8dbe0;
}
.cate-title{
    padding:10px 0 10px 30px;
    float: left;

}
.cate-btn{
    float: right;
    text-align: right;
    padding:10px;
    a{
        margin-left:10px;
    }
}
.btn-edit{
    display:inline-block;
    height:15px;
    width:15px;
    background-size: 15px 15px;
    background-image: url('/assets/icons/pencil.svg');
    background-repeat: no-repeat;
}
.cate-btn a.btn-acco[aria-expanded="false"]:after {
    content: "";
    display:inline-block;
    height:15px;
    width:15px;
    background-size: 15px 15px;
    background-image: url('/assets/icons/chevron-circle-up-alt.svg');
    background-repeat: no-repeat;
}
.cate-btn [aria-expanded="true"]:after {
    content: "";
    display:inline-block;
    height:15px;
    width:15px;
    background-size: 15px 15px;
    background-image: url('/assets/icons/chevron-circle-down-alt.svg');
    background-repeat: no-repeat;
}

// order add step01 delivery
#radio-tab2,
#radio-tab3{
    margin-top: 15px;
    background-color: #f5f8fa;
    padding: 10px 15px;
    p{
        margin-bottom:5px;
    }
}


.display-del-btn-sp{
    display: none;
 }
@media screen and (max-width:767px){
    .c-header-nav-link span.account-name{
        display: none;
    }
    .display-del-btn{
        display: none;
    }
    .display-del-btn-sp{
        display: inline-block;
    }
    .btn-box{
        text-align: center;
        margin-bottom: 30px;
        button{
            display: block;
            width: 100%;
            color: #fff;
            margin-left:0;
            margin-bottom: 15px;
        }
        a{
            display: block;
            width: 100%;
            color: #fff;
            margin-left:0;
            margin-bottom: 15px;
        }
        a:not([href]):not([tabindex]), a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus{
            color: #fff;
        }
    }
    .btn-upload{
        margin-top:8px;
        margin-left:0;
    }
 }
